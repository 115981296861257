/*modal theme*/
.custom-ant-modal .ant-modal-header{
    border-bottom: none;
    text-align: center;
    /*background-color: #F8F9FA;*/
}
.custom-ant-modal .ant-modal-title{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #14161B;
}
/*.custom-ant-modal .ant-modal-content{
    background-color: #F8F9FA;
}*/
.custom-ant-modal .ant-modal-footer{
    border-top: none;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
}
.custom-ant-modal .ant-modal-footer .ant-btn{
    width: 175px;
    height: 44px;
    font-size: 12px;
    line-height: 14px;
    color: #98A3B9;
}
.custom-ant-modal .ant-modal-footer .ant-btn-primary{
    text-align: center;
    color: #FFFFFF;
    /*background: #98A3B9;
    border-color: #98A3B9;*/
}
.custom-ant-modal .ant-form-item-label > label{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #5F6377;
    padding-bottom: 7px;
}
.custom-ant-modal .ant-form-vertical .ant-form-item {
    padding: 10px;
    /*background: #F0F1F2;*/
    margin-bottom: 6px;
}
.custom-ant-modal .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background: #98A3B9;
    border-color:#98A3B9;
}
/*
.form__input.PhoneInput .PhoneInputInput{
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    display: inline-flex;
}
.form__input.PhoneInput .PhoneInputInput:focus{
    outline: none;
}
.form__input.PhoneInput .PhoneInputCountrySelect{
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    border: none;
}
*/
